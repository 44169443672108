<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="400px">
            <v-card>
                <v-card-title>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <label class="label-input">{{ $t("employee_using_ot.date") }}</label>
                        <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
                            :append-to-body="true" name="founding_date" v-model="date"></DatePicker>
                        <v-row class="mt-3">
                            <v-col cols="5" md="5">
                                <v-text-field v-model="check_in" type="time"></v-text-field>
                            </v-col>
                            <v-col cols="2" md="2" class="text-center lfont">{{
                                $t("employee_using_ot.time-bw")
                            }}</v-col>
                            <v-col cols="5" md="5">
                                <v-text-field v-model="check_out" type="time"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="add_date()">
                        OK
                    </v-btn>
                    <v-btn color="red darken-1" text @click="close()">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        dialog: ''
    },
    data() {
        return {
            date: "",
            check_in: "",
            check_out: ""
        }
    },
    methods: {
        close() {
            this.$emit('close', false);
        },
        add_date() {
            if (this.date == '') {
                this.$notification.ShowErrors(
                    "top-right",
                    "danger",
                    3000,
                    'ກະລຸນາເລືອກວັນທີ່ ແລະ ເວລາໃຫ້ຄົບຖ້ວນ !!'
                );
                return;
            } else if (this.check_in > this.check_out) {
                this.$notification.ShowErrors(
                    "top-right",
                    "danger",
                    3000,
                    'ເວລາບໍຖືກຕ້ອງ !!'
                );
                return;
            }
            let date = { date: this.date, start_time: this.check_in, end_time: this.check_out };
            this.$emit('close', false);
            this.$emit('add', date);
            this.clear_data();
        },
        clear_data() {
            this.date = ''
            this.check_in = ''
            this.check_out = ''
        }
    },

}
</script>

<style lang="scss" scoped></style>