import { render, staticRenderFns } from "./create.vue?vue&type=template&id=1c54d20d&scoped=true&"
import script from "./create.vue?vue&type=script&lang=js&"
export * from "./create.vue?vue&type=script&lang=js&"
import style0 from "./create.vue?vue&type=style&index=0&id=1c54d20d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c54d20d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAutocomplete,VBtn,VCol,VForm,VIcon,VList,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VListItemTitle,VRow,VTextarea})
